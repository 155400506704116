export const CONSTANT = {
  LOCAL_STORAGE_LANG_KEY: 'MR_LANG_KEY',
  LOCAL_STORAGE_CATEGORY_KEY: 'MR_CATEGORY_KEY',
  LANGUAGES: [
    { key: 'en', name: 'english', flag: 'US' },
    { key: 'es', name: 'spanish', flag: 'ES' },
  ],
  SOCKET_TOTAL_PRODUCTS_ACTIVE_SHOPPING_CART: 'total-products-shopping-cart',
  SOCKET_TOTAL_ACTIVE_ORDERS: 'total-active-orders',
  SOCKET_TOTAL_MESSAGES: 'total-messages',
  SOCKET_NEW_MESSAGES: 'receive-message',
  SOCKET_EMIT_MESSAGE_EVENT: 'send-message',
  SOCKET_EMIT_READ_MESSAGE: 'read-message',
  SOCKET_UPDATE_HEADER_EVENT: 'update-header',
  SOCKET_SEND_FILE_EVENT: 'send-file',
  SOCKET_RECEIVE_FILE_EVENT: 'receive-file',
  INBOX_ACCEPT_FILE: `image/jpg, image/jpeg, image/png, image/gif, image/tiff, image/bmp, application/pdf, application/vnd.ms-excel`,
  INBOX_MAX_FILE_SIZE: 5242880,
  USER_KEY: 'user',
  PROFILE_KEY: 'PROFILE',
  TOKEN_KEY: 'token',
  REFRESH_TOKEN: 'refresh_token',
  ICON_MAX_FILE_SIZE: 5242880,
  ICON_ACCEPT_FILE: `image/jpg, image/jpeg, image/png`,
  CERTIFICATE_ACCEPT_FILE: `image/jpg, image/jpeg, image/png, application/pdf`,
  CERTIFICATE_MAX_FILE_SIZE: 5242880,
  ERRORS_SERVICES: {
    caseValidLesterCode:
      'Invalid request data product.lesterCode is required if product.categoryCode starts with auto electrical systems category code',
    caseValidOECode: '"oemNumber" is not allowed to be empty',
    invalidRequest: 'Invalid request data',
    accessDenied: 'Access denied',
    accessDeniedCode: 403,
  },
  WAREHOUSES: {
    china: 'China',
    usa: 'USA',
    panama: 'Panama',
  },
  STATUS: {
    REG_COMPLETED: 'REG_COMPLETED',
    REG_BASIC_COMPLETED: 'REG_BASIC_COMPLETED',
  },
  SHOPPING_CART_FILES: {
    MODULES: {
      ORDER: 'ORDER',
      SHOPPING_CART: 'SHOPPING_CART',
      QUOTES: 'QUOTE',
    },
    STATES: {
      INITIAL: 'initial',
      ORDER: 'order',
      QUOTES: 'quote',
      RESPONSE: 'response',
    },
  },
  PATTERNS: {
    ONLY_NUMBERS: /^[0-9]\d*$/i,
    ONLY_LETTERS: /^[A-Za-zÀ-ÿ]*$/,
    URL: /^([a-zA-ZÀ-ÿ]+(\s*[a-zA-ZÀ-ÿ]*)*[a-zA-ZÀ-ÿ])(.*\.([a-zA-ZÀ-ÿ]+(\s*[a-zA-ZÀ-ÿ]*)*[a-zA-ZÀ-ÿ]))/,
    EMAIL: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  },
  SHOPPING_CART_QTY: {
    ONE_PLUS: 'ONE_PLUS',
    MANY_PLUS: 'MANY_PLUS',
    ONE_MINUS: 'ONE_MINUS',
    CUSTOM_QTY: 'CUSTOM_QTY',
  },
  ROLES: {
    BUYER: 'BUYER',
    SELLER: 'SELLER',
    MANUFACTURER: 'MANUFACTURER',
    CUSTOMER_SERVICE: 'CUSTOMER_SERVICE',
  },
  LABEL_MANUFACTURER_PRICE: 'FOB',
}
